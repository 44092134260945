import React, { useState, useEffect } from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import HoverRating from "./HoverRating";
import RadioGroupRating from "./RadioGroupRating"
import Rating from '@material-ui/lab/Rating';
import { SubmitFeedback, SubmitEmployeeFeedback, GetEmployeeFeedback, GetAIRatings } from "./Services/Feedbackservice";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import "./App.css";

const ContentToast = ({ id, children, textareavalues, onTextareaChange, onRatingChange }) => {
  const [aiRating, setAIRatings] = useState();
  const [value, setAIRatingsValue] = useState(0);

  const handleInternalTextareaChange = (e) => {
    const newValue = e.target.value;
    onTextareaChange(id, newValue);
  };

  const handleRatingChange = (id, newValue) => {
    onRatingChange(id, newValue);
  };
 
  const handleLostFocus = (e) => {
    if(textareavalues.length === 0 || textareavalues.length < id){
      return;
    }
    GetAIRatings(e.target.value).then(ratings => {
      setAIRatings(ratings.rating);
      setAIRatingsValue(ratings.rating);
      const searchText = ratings.keyPhrases;
      if( searchText != ''){
        const regex = new RegExp(`(${searchText})`, "gi");
        // Replace the found text with a span that highlights it
         const newValue = textareavalues[id-1].ClientFeedback.replace(regex, (match) => `<mark>${match}</mark>`);
         textareavalues[id-1].ClientFeedback = newValue;
      }
      
      textareavalues[id-1].AIRating = ratings.rating;
    });
  }

  
  return (
    <div class="fade toast show form-font" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <div class="mr-auto questions">{children}</div>
      </div>
      <div class="toast-body">
        <p class="text-large mr-8 mb-4 space-left-4">
          <div>
            <span class="rating-title-span">Rating :</span> <HoverRating id={id} onRatingChange={handleRatingChange}></HoverRating>
          </div>
        </p>
        <textarea id={id} rows="4" class="form-control" placeholder="Provide your feedback here (required)" value={textareavalues[0]?.Feedback} 
          onChange={handleInternalTextareaChange} onBlur={handleLostFocus}>
        </textarea>
        <p class="text-large mt-1 mr-8 mb-4 space-left-4">
          <div>
          <img  class="rating-title-span" src={"logo192.png"} width="18" height="22" alt="Logo" />
            <span class="rating-title-span"> AI Rating : {aiRating}  </span>
            <Rating name="read-only" value={value} precision={0.1} readOnly onChange={(event, newValue) => setAIRatingsValue(aiRating)}  />
            <span class="rating-title-span-note"> [*This is beta feature, and we are calculating AI rating based on your feedback analysis.]</span>
          </div>
        </p>
      </div>
    </div>
  );
};
const HeaderToast = ({ children }) => {
  return (
    <div class="fade toast show form-font" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-body headerstrip">
        {children}
      </div>
    </div>
  );
};

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${month}/${date}/${year}`;
}
const capitalizeFirst = str => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

const queryParams = new URLSearchParams(window.location.search)
const params = atob(queryParams.get("id")).split('&');
const empId = params[0];
const quarter = params[1];
const year = params[2];
//alert(btoa("1&2&2023"));
//alert(atob("aWQ9MiZxPTEmeT0yMDIz"));
const cDate = getDate();
var EmployeeName = '';

const App = () => {
  const [questions, setQuestions] = useState([]);
  const [feedbacks, setFeedback] = useState([]);
  const [Overallratings, setOverallRating] = useState(0);
  const [currentEmployee, setCurrentEmployee] = useState();
  const [isFeedbackSubmitted, setSubmitted] = useState(true);
  const [isFormLoad, setFormLoad] = useState(0);
  const [isSubmittingFeedback, setSubmitting] = useState(false);
  const [GeneralComment, setGeneralComment] = useState(0);

  useEffect(() => {    
    if(params.length == 1){
      setFormLoad(6);
    };
    GetEmployeeFeedback(empId, quarter, year).then(employeeFeedback => {
      const emp = employeeFeedback.employee;
      EmployeeName = emp.employeeName;
      setCurrentEmployee(emp);
      setSubmitted(employeeFeedback.isSubmitted);
      if (employeeFeedback.isSubmitted === false) {
        setSubmitting(true);
        setFormLoad(1);
        setQuestions(employeeFeedback.questions);
      }
      else if (employeeFeedback.isSubmitted) {
        setFormLoad(2);
      }
    });
  }, []);

  const handleTextareaChange = (id, value) => {
    var feedback = feedbacks.find(
      a => a.QuestionId === parseInt(id, 10)
    );

    if (feedback == null) {
      feedback = {
        QuestionId: id,
        ClientFeedback: value,
      };
      setFeedback([...feedbacks, feedback]);
    }
    else {
      feedback.ClientFeedback = value;
      setFeedback((prevModels) =>
        prevModels.map((model) =>
          model.QuestionId === feedback.QuestionId ? feedback : model
        ));
    }
  };


  const handleRatingChange = (id, value) => {
    var rating = feedbacks.find(
      a => a.QuestionId === id
    );

    if (rating == null) {
      rating = {
        QuestionId: id,
        Rating: value,
      };
      setFeedback([...feedbacks, rating]);
    }
    else {
      rating.Rating = value;
      setFeedback((prevModels) =>
        prevModels.map((model) =>
          model.QuestionId === rating.QuestionId ? rating : model
        ));
    }
  }

  const HandleOvRatingChange = (value) => {
    setOverallRating(() => (value));
  }

  const HandleGeneralComment = event => {
    setGeneralComment(event.target.value);
  }


  const handleSubmit = () => {
    var isValidate = feedbacks.length == 3 && GeneralComment;
 
    if (isValidate) {
      var shouldSubmit = window.confirm(
        "Are you sure that you want to submit feedback form?"
      );
  
      if (isValidate) {
        var empFeedback = {
          employeeId: currentEmployee.employeeId,
          quarter: quarter,
          year: year,
          overallSatisfaction: Overallratings,
          generalComment: GeneralComment
        }
  
        SubmitEmployeeFeedback(empFeedback)
          .then(empFeedbackId => {
            feedbacks.forEach(element => {
              element.EmployeeFeedbackId = empFeedbackId;
            });
            SubmitFeedback(feedbacks);
            setFormLoad(5);
          });
      }
    }
    else{
      if(GeneralComment){
        alert("Please fill all required feedback's comment!");
      }
      else if(feedbacks.length == 3){
        alert("Please fill general comment!");
      }
      else{
        alert("Please fill all required feedback's and general comment!");
      }
    }
    
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure want to cancel?")) {
      window.location.reload(false);
    }
  };


  
  if (isFormLoad === 1) {
    return (

      <Container className="p-3">

        <Jumbotron>
           
            <div class="div100">
              <div class="div40">
                <img src={"Logo.png"} width="160" height="75" alt="Logo" />
              </div>
              <div class="div20">
            
              </div>
              <div class="div40 div-right">
                <h6 class="dateheader">Date- {cDate}</h6>
            
                <h5 class="qurterheader"><span>Q{quarter}-{year}</span></h5>
              </div>
            </div>


        
          
        <hr></hr>
            <div><h1 className="header">
              Feedback form for <span class="label label-default page-title-name">{EmployeeName} </span> </h1></div>
              <div class="clear"></div>
         

          <HeaderToast> Please provide responses on below questions. </HeaderToast>
          {
            questions.map((question, index) => {
              return (
                <ContentToast id={question.questionId} textareavalues={feedbacks} onTextareaChange={handleTextareaChange} onRatingChange={handleRatingChange} >
                  {index + 1}. {question.question}
                </ContentToast>
              );
            })
          }
          <div>

          </div>
          <div class="fade toast show form-font" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header">
              <strong class="mr-auto">General Comment</strong>
            </div>
            <div class="toast-body">
              <textarea rows="8" class="form-control" placeholder="Provide your general comment (required)"
                onChange={HandleGeneralComment}>
              </textarea>
            </div>
          </div>

          <HeaderToast>
            <RadioGroupRating OnRatingChange={HandleOvRatingChange}></RadioGroupRating>
          </HeaderToast>
          <HeaderToast>
            <div class="footer">
              <Button variant="success"
                onClick={handleSubmit}>Submit</Button> <Button variant="info" onClick={handleCancel}>Cancel</Button>
            </div>
          </HeaderToast>
        </Jumbotron>
      </Container>)
  }
  else if (isFormLoad === 2) {
    return (

      <Container className="p-3 middle">
        <img src={"submit.jpg"} width="300" height="300" />
        <br /><br /><br />
        <Jumbotron>
          <h3 className="taost">Feedback form for <span className="page-title-name">{EmployeeName}</span> has been already submitted.  Much obliged!</h3>
        </Jumbotron>
        <a href="https://www.infysion.com/">
          <br /><br /> <br /><br />
          <img src={"Logo.png"} width="140" height="68" alt="Logo" /></a>
        <br /><br />
        <div>Email: <a href="mailto:solutioning@infysion.com">solutioning@infysion.com</a>
        </div>
        <div>Phone: <span className="page-title-name">+1 (866) 422-6603</span></div>
      </Container>
    )
  }
  else if (isFormLoad === 0) {
    return (

      <div className="textAlign">
        <br /><br /><br />
        <img src={"Loading.gif"} />
      </div>

    )
  }
  else if (isFormLoad === 4) {
    return (
      <div className="textAlign">

        <img src={"ThankYou.gif"} width="500" height="500" />
        <a href="https://www.infysion.com/">
          <br />
          <img src={"Logo.png"} width="162" height="75" alt="Logo" /></a>
        <br /><br />
        <div>Email: <a href="mailto:solutioning@infysion.com">solutioning@infysion.com</a>
        </div>
        <div>Phone: <span className="page-title-name">+1 (866) 422-6603</span></div>
      </div>
    )
  }
  else if (isFormLoad === 5) {
    return (
      <div className="textAlign">

        <img src={"ThankYou.gif"} width="450" height="450" />
        <br />
        <Jumbotron>
          <h3 className="taost">Thanks, We appreciate you providing us with valuable feedback. We're here for you, anytime.</h3>
        </Jumbotron>
       
        <a href="https://www.infysion.com/">
          <br />
          <img src={"Logo.png"} width="162" height="75" alt="Logo" /></a>
        <br /><br />
        <div>Email: <a href="mailto:solutioning@infysion.com">solutioning@infysion.com</a>
        </div>
        <div>Phone: <span className="page-title-name">+1 (866) 422-6603</span></div>
      </div>
    )
  }
  else if (isFormLoad === 6) {
    return (
      <div className="textAlign">

        <img src={"ThankYou.gif"} width="450" height="450" />
        <br />
        <Jumbotron>
          <h3 className="taost">Need help? We're ready to assist you.</h3>
        </Jumbotron>
       
        <a href="https://www.infysion.com/">
         
          <img src={"Logo.png"} width="162" height="75" alt="Logo" /></a>
        <br /><br />
        <div>Email: <a href="mailto:solutioning@infysion.com">solutioning@infysion.com</a>
        </div>
        <div>Phone: <span className="page-title-name">+1 (866) 422-6603</span></div>
      </div>
    )
  };
 

};

export default App;
